import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Snackbar,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddIcon from '@mui/icons-material/Add';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



function ProduitTable() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data,setData] = useState([])
  const navigate = useNavigate();

  const [open ,setOpen] = useState(false)
  const [opener ,setOpener] = useState(false)
  const [message , setMessage ] = useState("")

  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    setFilteredData(
      data.filter(produit =>
        produit.nom_produits.toLowerCase().includes(searchQuery.toLowerCase()) ||
        produit.nom_produits.toLowerCase().includes(searchQuery.toLowerCase()) ||
        produit.matieres_actives.map(ma => ma.Matieres_actives).join(', ').toLowerCase().includes(searchQuery.toLowerCase()) ||
        produit.Fournisseur.toLowerCase().includes(searchQuery.toLowerCase()) ||
        produit.NAutorisation.toLowerCase().includes(searchQuery.toLowerCase()) ||
        produit.Prix_Unite.includes(searchQuery) ||
        produit.Prix_Vent_Unite.includes(searchQuery)
      )
    );
  }, [searchQuery, data]);


  const getProduis = () => {
    axios.get("http://pestima.test/api/produits")
      .then((res) => {
        setData(res.data);
      });
  };

  useEffect((res)=>{
    getProduis()
  },[])

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleDelete = (id) => {
    axios.delete("http://pestima.test/api/produits/" + id)
    .then((res) => {
      if (res.data.error) {
        setOpener(true);
        setMessage(res.data.message);
      } else {
        setOpen(true);
        setMessage(res.data.message);
        getProduis();
      }
    });
  handleClose();
  };


  const handleCloseer = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpener(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex' }}>
        <Box component="main" sx={{ flexGrow: 1, p: 1.5, overflowX: 'hidden' }}>
          <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}>
              Produits
            </Typography>
            <TextField
              id="search-sales"
              type="search"
              variant="outlined"
              placeholder="Search Produit..."
              sx={{
                mb: 2,
                fontFamily: 'Arial, sans-serif',
                width: '100%',
                borderRadius: '9px',
                backgroundColor: '#f2f3f3',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '9px',
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <TableContainer sx={{ width: '100%', mb: 2, borderRadius: '9px', border: '1px solid #C0C0C0' }}>
              <Table sx={{ width: '100%', border: '1px solid #C0C0C0', fontFamily: 'Roboto', mb:"0px" }} aria-label="users table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>ID</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Nom de Produit</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Matières Actives</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Fournisseurs</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Organisme Cible</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>N d'Autorisation</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Prix Unite</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Prix Vent Unite</TableCell>
                    <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', color: '#000000', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', backgroundColor: '#F2F2F2' }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((row) => (
                    <TableRow key={row.id_produits} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.id_produits}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.nom_produits}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.matieres_actives ? row.matieres_actives.map(ma => ma.Matieres_actives).join(', ') : null}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Fournisseur}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.ORGANISME_CIBLE}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.NAutorisation}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Prix_Unite}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>{row.Prix_Vent_Unite}</TableCell>
                      <TableCell style={{ border: '1px solid #dbd8e3', padding: '8px', fontFamily: 'Roboto, sans-serif' }}>
                        <IconButton
                          aria-controls="simple-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClick(event, row)}
                        >
                          <ArrowDropDownIcon sx={{ color: 'red' }} />
                        </IconButton>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)&& selectedRow?.id_produits === row.id_produits}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={()=>navigate("/UdpateProduits/"+row.id_produits)}>Modifier</MenuItem>
                          <MenuItem onClick={()=>handleDelete(row.id_produits)}>Supprimer</MenuItem>
                        </Menu>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Button variant="contained" sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:'15%',
                alignSelf:'flex-end'
              }} onClick={()=>navigate("/AddProduct")}>
                <AddIcon sx={{ mr: 1 }} />
                Ajouter Nouveau
              </Button>
          </Box>
        </Box>
      </Box>

      <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
        <Alert
          onClose={handleCloseer}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSuccess}>
        <Alert
          onClose={handleCloseSuccess}
          severity='success'
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>

    </Box>
  );
}

export default ProduitTable;
