import React, { useEffect, useRef, useState } from 'react';
import Navbar from './Navbar.js';
import { Box, Checkbox, FormControlLabel, Tabs, Tab, Typography, TextField, IconButton ,Button } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import axios from 'axios';
import './styles/FCPStylePDf.css'
import fulllogo from './img/full_logo_png.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf'
import { useParams } from 'react-router-dom';

const formatDate = (newdate = new Date()) => {
  const date = new Date(newdate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

function TableFcp() {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedPost, setSelectedPost] = useState(null);
  const [postEtats, setPostEtats] = useState({});
  const [Posts , setPosts] = useState([])
  const [filterOptions , setfilterOptions]=useState([])
  const certificateRef = useRef();
  const {idpassage} = useParams()
  const [isclient , setisclient] = useState()
  const getPlans=()=>{
    axios.get("http://pestima.test/api/postsbypassage/"+idpassage)
    .then((res)=>{
      setPosts(res.data)
      getEtats(res.data)
    })
  }

  const getEtats = (P) => {
    axios.get("http://pestima.test/api/etats")
      .then((res) => {
        setfilterOptions(res.data);
  
        // Step 1: Update selectedFilters
        const newSelectedFilters = {};
        res.data.forEach((E) => {
          newSelectedFilters[E.ID_Etat] = true;
        });
        setSelectedFilters(newSelectedFilters);
  
        // Step 2: Initialize postEtat
        const newPostEtat = {};
        P.forEach((post) => {
          newPostEtat[post.ID_Post] = {};
          res.data.forEach((E) => {
            if(E.ID_Etat === post.ID_Etat){
              newPostEtat[post.ID_Post][E.ID_Etat] = true;
            }else{
              newPostEtat[post.ID_Post][E.ID_Etat] = false;
            }
            
          });
        });
        
        setPostEtats(newPostEtat);
      })
      .catch((err) => {
        console.error("Error fetching etats:", err);
      });
  }
  
  

  useEffect(()=>{
    getPlans()
    const isClinet = JSON.parse(localStorage.getItem("isClinet"))
    setisclient(isClinet)
  },[])

const SendToDB=()=>{
        Posts.map((P)=>{
          axios.put('http://pestima.test/api/posts/'+P.ID_Post,{...P , ID_Etat:Object.entries(postEtats[P.ID_Post]).find(([key, value]) => value === true)[0] })
          .then((res)=>{

          })
        })
        
 
    }

    const EtatNumber = (Etat) => {
      let Counter = 0 ;
      Posts.map((p)=>{
        if(postEtats[p.ID_Post][Etat]){
          Counter++
        }
      })
      return Counter
    };


  const handleFilterChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFilters({
      ...selectedFilters,
      [name]: checked,
    });
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedPost(null); // reset selected post when tab changes
  };

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleObservationChange = (event) => {
    const newObservation = event.target.value;
    setPosts(Posts.map((post)=>
      post.ID_Post === selectedPost.ID_Post ? {...post , OBSERVATIONS : newObservation} : post
    ))
  };

  const handleEtatChange = (postId, option) => {
    const newPostEtats = { ...postEtats };
    // Initialize the post state if it doesn't exist
    if (!newPostEtats[postId]) {
      newPostEtats[postId] = {};
    }
    // Uncheck all other options
    Object.keys(newPostEtats[postId]).forEach(key => {
      newPostEtats[postId][key] = false;
    });
    // Check the selected option
    newPostEtats[postId][option] = true;
    setPostEtats(newPostEtats);
  };

  const filteredPosts = Posts.filter((post) => post.INTERNE === selectedTab);

  const downloadPdf = () => {


    // Create an iframe element
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '100%';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.style.opacity = '0'; // Make it invisible
    document.body.appendChild(iframe);

    // Write content to the iframe
    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write('<!DOCTYPE html><html><head><title>PDF Content</title>');
    doc.write('</head><body>');
    doc.write(`
      <div class="container">
        <table class="header-table">
          <tbody>
            <tr>
              <td class="logo" rowSpan="5"><img src="${fulllogo}" width="180px" /></td>
              <td class="title" colSpan="2" style="text-align: center;">Processus Management de la Qualité</td>
              <td class="info">Code: EN.MQ.01</td>
            </tr>
            <tr>
              <td class="title" colSpan="2" style="text-align: center;">Enregistrement</td>
              <td class="info">Version: 01</td>
            </tr>
            <tr>
              <td class="title" colSpan="2" rowSpan="2" style="text-align: center;">FICHE DE CONTRÔLE DES POSTES D’APPATAGES</td>
              <td class="info">Date: ${formatDate()}</td>
            </tr>
            <tr>
              <td class="info">Page 1/1</td>
            </tr>
          </tbody>
        </table>
    
        <section class="header-info">
          <div>
            <p>Client :</p>
            <p>Nombre de postes mis en place : ${Posts.length}</p>
            <p>Produits utilisés :</p>
            <p style="margin-left: 70px;">- Boite externe : ${Posts.filter(p => p.INTERNE === 0).length}</p>
            <p style="margin-left: 70px;">- Boite interne : ${Posts.filter(p => p.INTERNE === 1).length}</p>
          </div>
          <div>
            <p>Date : ${formatDate(Posts[0] ? Posts[0].passage.date_passage : null)}</p>
            <p>Responsable :</p>
          </div>
        </section>
    
        <h4>ETAT DES POSTES</h4>
        <section class="etat-des-postes">
          <table>
            <thead>
              <tr>
                ${filterOptions.map(option => 
                  selectedFilters[option.ID_Etat] ? 
                  `<th key="${option.Etat}">(${option.Etat}) ${option.description}</th>` : ''
                ).join('')}
              </tr>
            </thead>
            <tbody>
              <tr>
                ${filterOptions.map(option => 
                  selectedFilters[option.ID_Etat] ? 
                  `<td key="${option.Etat}">${EtatNumber(option.ID_Etat)}</td>` : ''
                ).join('')}
              </tr>
            </tbody>
          </table>
        </section>
    
        <h4>POSTES – PIEGES – PRODUIT</h4>
        <section class="postes-pieges-produit">
          <table>
            <thead>
              <tr>
                <th rowSpan="2">INTERNE</th>
                <th colSpan="${filterOptions.filter(option => option.intern === 1 && selectedFilters[option.ID_Etat] === true).length + 2}">OBSERVATIONS</th>
                <th rowSpan="2">Externe</th>
                <th colSpan="${filterOptions.filter(option => option.intern === 0 && selectedFilters[option.ID_Etat] === true).length + 2}">OBSERVATIONS</th>
              </tr>
              <tr>
                <th>N°</th>
                ${filterOptions.map(option => 
                  selectedFilters[option.ID_Etat] && option.intern === 1 ? 
                  `<th key="${option.Etat}">${option.Etat}</th>` : ''
                ).join('')}
                <th>OBSERVATIONS</th>
                <th>N°</th>
                ${filterOptions.map(option => 
                  selectedFilters[option.ID_Etat] && option.intern === 0 ? 
                  `<th key="${option.Etat}">${option.Etat}</th>` : ''
                ).join('')}
                <th>OBSERVATIONS</th>
              </tr>
            </thead>
            <tbody>
              ${Posts.map((p, index) => `
                <tr key="${p.ID_Post}">
                  <td>${index + 1}</td>
                  <td>${p.ID_Post}</td>
                  ${filterOptions.map(option => 
                    selectedFilters[option.ID_Etat] && option.intern === 1 ? 
                    `<td key="${option.Etat}">${postEtats[p.ID_Post][option.ID_Etat] && p.INTERNE ? 'X' : ''}</td>` : ''
                  ).join('')}
                  <td>${p.INTERNE && p.OBSERVATIONS !== null ? p.OBSERVATIONS : ''}</td>
                  <td>${index + 1}</td>
                  <td>${p.ID_Post}</td>
                  ${filterOptions.map(option => 
                    selectedFilters[option.ID_Etat] && option.intern === 0 ? 
                    `<td key="${option.Etat}">${postEtats[p.ID_Post][option.ID_Etat] && !p.INTERNE ? 'X' : ''}</td>` : ''
                  ).join('')}
                  <td>${!p.INTERNE && p.OBSERVATIONS !== null ? p.OBSERVATIONS : ''}</td>
                </tr>
              `).join('')}
              
            </tbody>
          </table>
        </section>
      </div>
    `);
    
    doc.write('</body></html>');
    doc.close();

    // Wait for the iframe content to be fully loaded
    iframe.onload = () => {
        const pdf = new jsPDF('portrait', 'pt', 'a4');
        pdf.html(iframe.contentWindow.document.body, {
            callback: (doc) => {
                doc.save('fcp.pdf');
                document.body.removeChild(iframe); // Remove the iframe after generating PDF
            },
            x: 10,
            y: 10,
            html2canvas: { scale: 0.39 },
            width: 190 // A4 width in mm (210mm) minus margins
        });
    };
};

  

  return (
    <Box>
      <Navbar />
      {!isclient ? 
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
          <IconButton sx={{ mr: 1 }} onClick={() => { /* TODO: Add filter functionality */ }}>
            <FilterListIcon />
          </IconButton>
          {filterOptions.map((option) => (
            <FormControlLabel
              key={option.ID_Etat}
              control={
                <Checkbox
                  checked={selectedFilters[option.ID_Etat] || false}
                  onChange={handleFilterChange}
                  name={option.ID_Etat.toString()}
                  sx={{ color: '#930e0e',
                    '&.Mui-checked': {
                      color: '#930e0e',
                    },
                }}
                />
              }
              label={option.Etat}
            />
          ))}
          <IconButton onClick={() => { /* TODO: Add filter functionality */ }}>
            <MoreHorizIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          TabIndicatorProps={{sx: {backgroundColor: '#930e0e'}}}
          sx={{"& button":{color:'#930e0e'}, "& button.Mui-selected":{color:'#930e0e'} }}
        >
          <Tab label="intern" value={1} />
          <Tab label="extern" value={0} />
        </Tabs>
        </Box>
        <Box sx={{ display: 'flex' }}>
        <Box sx={{ flex: 1 }}>
  {filteredPosts.map((post , index) => (
    <Typography
      key={post.ID_Post}
      onClick={() => handlePostClick(post)}
      sx={{ 
        cursor: 'pointer', 
        p: 1, 
        backgroundColor: post === selectedPost ? '#930e0e' : 'white',
        color: post === selectedPost ? 'white' : 'black',  // Add this line
        borderRadius: '4px', 
      }}
    >
      Post {index+1}
    </Typography>
  ))}
</Box>
          {selectedPost && (
            <Box sx={{ flex: 2, p: 2, fontFamily:'roboto',
                borderRadius: '5px',
                boxShadow: 'none',
                border: '0.5px solid #C0C0C0',
                '& > :not(style)': { mb: 2 },}}>
              <Typography>Code Ref: {selectedPost.Code_Ref}</Typography>
              <Typography>N°: {selectedPost.ID_Post}</Typography>
              <Typography>Emplacement: {selectedPost.Emplacement}</Typography>
              <Typography>
                Etat: <span> </span>
                {filterOptions.map((option) => (
                  selectedFilters[option.ID_Etat] && (
                    option.intern === selectedTab ? 
                      <FormControlLabel
                      key={option.ID_Etat}
                      control={
                        <Checkbox
                          checked={postEtats[selectedPost.ID_Post] ? postEtats[selectedPost.ID_Post][option.ID_Etat] : false}
                          onChange={() => handleEtatChange(selectedPost.ID_Post, option.ID_Etat)}
                          sx={{ color: '#930e0e',
                              '&.Mui-checked': {
                                color: '#930e0e',
                              },
                          }}
                        />
                      }
                      label={option.Etat}
                    />
                      :null
                  )
                ))}
              </Typography>
              <Typography>Observation:</Typography>
              <TextField
                value={ Posts.find((post) => post.ID_Post === selectedPost.ID_Post).OBSERVATIONS }
                onChange={handleObservationChange}
                multiline
                rows={4}
                sx={{ backgroundColor: '#f2f3f3',
                    '& label.Mui-focused': {
                      color: '#930E0E',
                      fontWeight: 'bold',
                    },
                    '& .MuiOutlinedInput-root': {
                      '&:hover fieldset': {
                        borderColor: '#930E0E',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#930E0E',
                      },
                    },
                  }}
                variant="outlined"
                fullWidth
              />
            </Box>
          )}
        </Box>
      </Box>
      :
      
      <div className="container"
          
      >
        <table className="header-table">
            <tbody>
                <tr>
                    <td className="logo" rowSpan="5"><img src={fulllogo} width='180px' /></td>
                    <td className="title" colSpan="2" style={{ textAlign: 'center' }}>Processus Management de la Qualité</td>
                    <td className="info">Code: EN.MQ.01</td>
                </tr>
                <tr>
                    <td className="title" colSpan="2" style={{ textAlign: 'center' }}>Enregistrement</td>
                    <td className="info">Version: 01</td>
                </tr>
                <tr>
                    <td className="title" colSpan="2" rowSpan="2" style={{ textAlign: 'center' }}>FICHE DE CONTRÔLE DES POSTES D’APPATAGES</td>
                    <td className="info">Date: {formatDate()}</td>
                </tr>
                <tr>
                    <td className="info">Page 1/1</td>
                </tr>
            </tbody>
        </table>

        <section className="header-info">
            <div>
                <p>Client :</p>
                <p>Nombre de postes mis en place : {Posts.length}</p>
                <p>Produits utilisés :</p>
                <p style={{ marginLeft: '70px' }}>- Boite externe : {Posts.filter((p) => p.INTERNE === 0).length}</p>
                <p style={{ marginLeft: '70px' }}>- Boite interne : {Posts.filter((p) => p.INTERNE === 1).length}</p>
            </div>
            <div>
                <p>Date : {formatDate(Posts[0] ? Posts[0].passage.date_passage : null)}</p>
                <p>Responsable :</p>
            </div>
        </section>

        <h4>ETAT DES POSTES</h4>
        <section className="etat-des-postes">
            <table>
                <thead>
                    <tr>
                    {filterOptions.map((option) => (
              selectedFilters[option.ID_Etat] && (
                              <th key={option.Etat}>{"("+option.Etat+")"+ option.description}</th>
              )))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    {filterOptions.map((option) => (
              selectedFilters[option.ID_Etat] && (
                              <td key={option.Etat}>{EtatNumber(option.ID_Etat)}</td>
              )))}
                    </tr>
                </tbody>
            </table>
        </section>

        <h4>POSTES – PIEGES – PRODUIT</h4>
        <section className="postes-pieges-produit">
            <table>
                <thead>
                    <tr>
                        <th rowSpan="2">INTERNE</th>
                        <th colSpan={filterOptions.filter(option => option.intern === 1 && selectedFilters[option.ID_Etat] === true).length + 2}>OBSERVATIONS</th>
                        <th rowSpan="2">Externe</th>
                        <th colSpan={filterOptions.filter(option => option.intern === 0 && selectedFilters[option.ID_Etat] === true).length + 2}>OBSERVATIONS</th>
                    </tr>
                    <tr>
                        <th>N°</th>
                        {filterOptions.map((option) => (
              selectedFilters[option.ID_Etat] && (
                option.intern === 1 ? 
                              <th key={option.Etat}>{option.Etat}</th>
                              : null
              )))}
                        
                        <th>OBSERVATIONS</th>
                        <th>N°</th>
                        {filterOptions.map((option) => (
              selectedFilters[option.ID_Etat] && (
                option.intern === 0 ? 
                              <th key={option.Etat}>{option.Etat}</th>
                              :null
              )))}
                        <th>OBSERVATIONS</th>
                    </tr>
                </thead>
                <tbody>
                {Posts.map((p,index)=>(
                                            <tr key={p.ID_Post}>
                                            <td>{index+1}</td>
                                            <td>{p.ID_Post}</td>
                                            {filterOptions.map((option) => (
                                              selectedFilters[option.ID_Etat] && (
                                                option.intern === 1 ?
                                              <td key={option.Etat}>{postEtats[p.ID_Post][option.ID_Etat] && p.INTERNE   ? "X" : null}</td>
                                            : null)))}
                                            
                                            <td>{p.INTERNE ?  p.OBSERVATIONS : null}</td>
                                            <td>{index+1}</td>
                                            <td>{p.ID_Post}</td>
                                            {filterOptions.map((option) => (
                                              selectedFilters[option.ID_Etat] && (
                                                option.intern === 0 ? 
                                                <td key={option.Etat}>{postEtats[p.ID_Post][option.ID_Etat] && !p.INTERNE  ? "X" : null}</td>
                                                  :null )))}
                                            <td>{!p.INTERNE ?  p.OBSERVATIONS : null}</td>
                                        </tr>
                  ))}

                </tbody>
            </table>
        </section>
    </div>
      
      }
      <Box sx={{display:"flex", justifyContent:"center" , columnGap:"50px"}}>
        {!isclient ? 
      <Button id="addRectButton" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"180px"
              }} onClick={SendToDB}>
            Enregistrer
          </Button>
          :null}

          <Button id="pdfDownload" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"180px"
              }} onClick={downloadPdf}>
            Telecharger Fichier
          </Button>
          </Box>




    </Box>
  );
}

export default TableFcp;
