import React, { useEffect, useState } from 'react';
import { Box, Button, Checkbox, FormControlLabel, CssBaseline, Link, TextField, Typography, Snackbar, Alert } from '@mui/material';
import Logo from './img/bob.png';
import PestControlIcon from '@mui/icons-material/PestControl';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Login = () => {
  const [email ,setemail] = useState('');
  const [pass , setpass] = useState('')
  const [open, setOpen] = useState(false)
  const [message , setmessage] =useState("")
  const navigate = useNavigate()
  const handlLogin=()=>{
    const data = {
      login : email ,
      Password : pass
    }
    axios.post("http://pestima.test/api/login",data)
    .then((res)=>{
      if(res.data.error){
        setmessage(res.data.message)
        setOpen(true)
      }else{
        localStorage.setItem("isloged",true);
        localStorage.setItem("UserData",JSON.stringify(res.data.user))
        if(res.data.user_type === 'user'){
          navigate("/Dashboard")
          localStorage.setItem("isClinet",false);
        }else{
          navigate("/ClientPage")
          localStorage.setItem("isClinet",true);
        }

      }
    })
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(()=>{
    const isloged = JSON.parse(localStorage.getItem('isloged'))
    if(isloged){
      navigate('/Dashboard')
    }
  },[])

  return (
    <>
      <CssBaseline />
      <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
        <Box
          component="img"
          src={Logo}
          alt="Workflow"
          sx={{
            width: '60%',
            height: '100vh',
            objectFit: 'cover'
          }}
        />
        <Box
          sx={{
            width: '40%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: 400, px: 4 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", color: '#006a18', textAlign: 'center' }}>
              <PestControlIcon sx={{ fontSize: 60, color: '#930E0E' }} />
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold", textAlign: 'center', color: 'black' }}>
              CONNECTEZ-VOUS
            </Typography>

            <form>
              <TextField
                fullWidth
                variant="outlined"
                label="Entrez votre Login Ou Votre Email"
                margin="normal"
                value={email}
                onChange={(e)=>setemail(e.target.value)}
                sx={{
                  '& label.Mui-focused': {
                    color: '#930E0E',
                    fontWeight: 'bold',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#930E0E',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#930E0E',
                    },
                  },
                }}
              />
              <TextField
                fullWidth
                variant="outlined"
                label="Entrez votre mot de passe"
                type="password"
                value={pass}
                onChange={(e)=>setpass(e.target.value)}
                margin="normal"
                sx={{
                  '& label.Mui-focused': {
                    color: '#930E0E',
                    fontWeight: 'bold',
                  },
                  '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                      borderColor: '#930E0E',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#930E0E',
                    },
                  },
                }}
              />
              <FormControlLabel
                control={<Checkbox name="remember" sx={{
                  color: 'rgba(0, 0, 0, 0.6)',
                  '&.Mui-checked': {
                    color: '#930E0E',
                  },
                }} />}
                label="Se souvenir de moi"
                sx={{ mb: 2 }}
              />
              <Button fullWidth variant="contained" sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '8px',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
              }}
              onClick={handlLogin}
              >
                Se connecter
              </Button>
              <Typography variant="body2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Link href="#" underline="none" sx={{ color: "black", fontWeight: "bold" }}>Mot de passe oublié ?</Link>
                <Box sx={{ flexGrow: 1, height: 1, bgcolor: 'divider', mx: 2 }} />
              </Typography>
            </form>
          </Box>
        </Box>
      </Box>

      {/* snackbars */}
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
