import React, { useState, useEffect, useRef } from 'react';
import Navbar from './Navbar.js';
import { Box, Typography, TextField, MenuItem, Select, FormControl, Grid, Checkbox, FormControlLabel, Button } from '@mui/material';
import axios from 'axios';
import './styles/InsectocutorCheckSheet.css';
import fulllogo from './img/full_logo_png.png'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useParams } from 'react-router-dom';

const equipements = [
  { id: 1, name: 'Tue-mouches en bon état', ouiChecked: false, nonChecked: false, action: '' },
  { id: 2, name: 'Fonctionnement des lampes', ouiChecked: false, nonChecked: false, action: '' },
  { id: 3, name: 'Fonctionnement des plaques', ouiChecked: false, nonChecked: false, action: '' },
  // add more equipements as needed
];

const inputStyles = {
  backgroundColor: '#f2f3f3',
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    height: '35px',
    '& fieldset': {
      borderColor: '#C0C0C0',
    },
    '&:hover fieldset': {
      borderColor: '#930e0e',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#930e0e',
    },
  },
  '& label.Mui-focused': {
    color: '#930e0e',
    fontWeight: 'bold',
  },
};

function TableFct() {
  const [insectocuteurs, setInsectocuteurs] = useState([]);
  const [selectedInsectocuteurId, setSelectedInsectocuteurId] = useState(null);
  const [selectedEquipements, setSelectedEquipements] = useState(equipements);
  const [observation, setObservation] = useState('');
  const [conseils, setConseils] = useState('');
  const certificateRef = useRef();

  const [isclient , setisclient] = useState()

  const formatDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const {idpassage} = useParams()

  const getAllPiege = () => {
    axios.get("http://pestima.test/api/piegesbypassage/"+idpassage)
      .then((res) => {
        console.log(res.data)
        const data = res.data.filter((piege) => piege.ID_Piege !== 0)
        setInsectocuteurs(data);
        setSelectedInsectocuteurId(data[0]?.ID_Piege);
        console.log(data)
      });
  };

  useEffect(() => {
    getAllPiege();
    const isClinet = JSON.parse(localStorage.getItem("isClinet"))
    setisclient(isClinet)
  }, []);

  const handleInsectocuteurClick = (insectocuteurId) => {
    setSelectedInsectocuteurId(insectocuteurId);
  };

  const updateInsectCount = (field, value) => {
    setInsectocuteurs(prevInsectocuteurs =>
      prevInsectocuteurs.map(insectocuteur =>
        insectocuteur.ID_Piege === selectedInsectocuteurId
          ? { ...insectocuteur, [field]: value }
          : insectocuteur
      )
    );
  };

  const handleFliesChange = (event) => {
    updateInsectCount('Mouches', event.target.value);
  };

  const handleBeesChange = (event) => {
    updateInsectCount('Abeilles', event.target.value);
  };

  const handleGnatsChange = (event) => {
    updateInsectCount('Moucherons', event.target.value);
  };

  const handleButterfliesChange = (event) => {
    updateInsectCount('Papillons', event.target.value);
  };

  const handleMosquitoesChange = (event) => {
    updateInsectCount('Moustiques', event.target.value);
  };

  const handleInsectocuteurActionChange = (event) => {
    updateInsectCount('ACTION', event.target.value);
  };

  const handleEquipementOuiChange = (id) => {
    setSelectedEquipements(
      selectedEquipements.map((equipement) =>
        equipement.id === id ? { ...equipement, ouiChecked: !equipement.ouiChecked, nonChecked: false } : equipement
      )
    );
  };

  const handleEquipementNonChange = (id) => {
    setSelectedEquipements(
      selectedEquipements.map((equipement) =>
        equipement.id === id ? { ...equipement, nonChecked: !equipement.nonChecked, ouiChecked: false } : equipement
      )
    );
  };

  const handleActionChange = (event, id) => {
    setSelectedEquipements(
      selectedEquipements.map((equipement) =>
        equipement.id === id ? { ...equipement, action: event.target.value } : equipement
      )
    );
  };

  const handleObservationChange = (event) => {
    setObservation(event.target.value);
  };

  const handleConseilsChange = (event) => {
    setConseils(event.target.value);
  };

  const SendToDB=()=>{

    axios.post('http://pestima.test/api/fctdata',{Observation :observation , Conseils : conseils , EtatTumoche : equipements[0].ouiChecked ,
      EtatTumocheAction : equipements[0].action , lampes : equipements[1].ouiChecked , lampesAction : equipements[1].action ,
      plaques : equipements[2].ouiChecked , plaquesAction : equipements[2].action , id_passage : idpassage
     }).then((res)=>{
      insectocuteurs.map((insectocuteur)=>{
        axios.put("http://pestima.test/api/pieges/"+insectocuteur.ID_Piege,{Mouches : insectocuteur.Mouches ,
          Moucherons:insectocuteur.Moucherons , Papillons : insectocuteur.Papillons , Moustiques : insectocuteur.Moustiques ,
          Abeilles : insectocuteur.Abeilles , ACTION : insectocuteur.ACTION
        })
      })
     })
  }

  const downloadPdf = () => {
    const input = certificateRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: [210, 297], // A4 size in landscape
      });
      pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
      pdf.save('Fiche de contrôle des insectocuteurs.pdf');
    });
  };



  return (
    <Box>
      <Navbar />

      <div className="container"
          ref={certificateRef}
          style={!isclient ? {
            position: 'absolute',
            left: '-9999px',
          }:{
            position: 'relative'
          }}
          id='containerpdf'>
        <table className="header-table">
          <tbody>
            <tr>
              <td className="logo" rowSpan="5" ><img src={fulllogo} width='180px' /></td>
              <td className="title" colSpan="2">Processus Management de la Qualité</td>
              <td className="info">Code: EN.MQ.01</td>
            </tr>
            <tr>
              <td className="title" colSpan="2">Enregistrement</td>
              <td className="info">Version: 01</td>
            </tr>
            <tr>
              <td className="title" colSpan="2" rowSpan="2">Fiche de contrôle des insectocuteurs</td>
              <td className="info">{formatDate()}</td>
            </tr>
            <tr>
              <td className="info">Page 1/1</td>
            </tr>
          </tbody>
        </table>

      <table className="info-table">
        <tbody>
          <tr>
            <td width="20%">Raison social du client:</td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td>Lieu d'intervention :</td>
            <td colSpan="3"></td>
          </tr>
          <tr>
            <td>Intervenant :</td>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>

      <table className="equipment-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="4">État des Équipements :</td>
          </tr>
          <tr>
            <td>Tue-mouches en bon état :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[0].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[0].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[0].action}</td>
          </tr>
          <tr>
            <td>Fonctionnement des lampes :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[1].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[1].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[1].action}</td>
          </tr>
          <tr>
            <td>Fonctionnement des plaques :</td>
            <td>
              <div className="checkbox-group">
                <label><input type="checkbox" checked={selectedEquipements[2].ouiChecked}/> Oui</label>
                <label><input type="checkbox" checked={selectedEquipements[2].nonChecked}/> Non</label>
              </div>
            </td>
            <td colSpan="2">Action à prévoir: {selectedEquipements[2].action}</td>
          </tr>
          <tr>
            <td>Détails supplémentaires :</td>
            <td colSpan="3"></td>
          </tr>
        </tbody>
      </table>

      <table className="sticky-plates-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="8">État des plaques collantes :</td>
          </tr>
          <tr>
            <th>Numéro de l'insectocuteurs</th>
            <th>Emplacement</th>
            <th>Moucherons</th>
            <th>Mouches</th>
            <th>Papillons</th>
            <th>Abeilles</th>
            <th>Moustiques</th>
            <th>ACTION</th>
          </tr>
          {insectocuteurs.map((i)=>(
            <tr>
            <td>Insectocuteurs {i.ID_Piege}</td>
            <td>{i.Emplacement}</td>
            <td>{isclient ? i.Watt == 20 ? parseInt(i.Moucherons) * 190 : parseInt(i.Moucherons) * 300 : i.Moucherons}</td>
            <td>{isclient ? i.Watt == 20 ? parseInt(i.Mouches) * 190 : parseInt(i.Mouches) * 300 :i.Mouches }</td>
            <td>{i.Watt == 20 ? parseInt(i.Papillons) * 190 : parseInt(i.Papillons) * 300}</td>
            <td>{i.Watt == 20 ? parseInt(i.Abeilles) * 190 : parseInt(i.Abeilles) * 300}</td>
            <td>{i.Watt == 20 ? parseInt(i.Moustiques) * 190 : parseInt(i.Moustiques) * 300}</td>
            <td>{i.ACTION}</td>
          </tr>
          ))}
          
        </tbody>
      </table>

      <p><em>*Méthode de calcule: Si la surface totale est de 100 cm² et la moyenne par cm² est de 5 insectes : Nombre Total d'insectes = 5 x 100 = 500 insectes.</em></p>

      <table className="recommendations-table">
        <tbody>
          <tr>
            <td className="section-title" colSpan="2">RECOMMANDATIONS</td>
          </tr>
          <tr>
            <td width="50%">Observations</td>
            <td>CONSEILS</td>
          </tr>
          <tr style={{height:"120px"}}>
            <td>{observation}</td>
            <td>
            {conseils}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

      {!isclient ?
      <Box sx={{ display: 'flex', flexDirection: 'column', p: 2 }}>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}
        >
          Etats des plaques collantes:
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            {insectocuteurs.map((insectocuteur) => (
              <Typography
                key={insectocuteur.ID_Piege}
                onClick={() => handleInsectocuteurClick(insectocuteur.ID_Piege)}
                sx={{ 
                  cursor: 'pointer', 
                  p: 1, 
                  backgroundColor: insectocuteur.ID_Piege === selectedInsectocuteurId ? '#930e0e' : 'white',
                  color: insectocuteur.ID_Piege === selectedInsectocuteurId ? 'white' : 'black',
                  borderRadius: '4px', 
                  border: insectocuteur.ID_Piege === selectedInsectocuteurId ? '1px solid #930e0e' : '1px solid transparent'
                }}
              >
                Insectocuteur {insectocuteur.ID_Piege}
              </Typography>
            ))}
          </Box>
          {selectedInsectocuteurId && (
            <Box sx={{ flex: 2, p: 2, fontFamily:'roboto',
                borderRadius: '5px',
                boxShadow: 'none',
                border: '0.5px solid #C0C0C0',
                '& > :not(style)': { mb: 2 },}}>
              <Typography>Code Ref: {insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Code_Ref}</Typography>
              <Typography>Emplacement: {insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Emplacement}</Typography>
              <Typography>Nombre D'insectes:</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Mouches"
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Mouches ?? ''}
                    onChange={handleFliesChange}
                    type="number"
                    sx={inputStyles}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                <TextField
                    label="Abeilles"
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId)?.Abeilles ?? ''}
                    onChange={handleBeesChange}
                    type="number"
                    sx={inputStyles}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Moucherons"
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Moucherons ?? ''}
                    onChange={handleGnatsChange}
                    type="number"
                    sx={inputStyles}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Papillons"
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Papillons ?? ''}
                    onChange={handleButterfliesChange}
                    type="number"
                    sx={inputStyles}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Moustiques"
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).Moustiques ?? ''}
                    onChange={handleMosquitoesChange}
                    type="number"
                    sx={inputStyles}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 2 }}>
              <Typography>Action:</Typography>
                <FormControl 
                  fullWidth 
                  variant="outlined" 
                  size="small"
                  sx={inputStyles}
                >
                  <Select
                    value={insectocuteurs.find(i => i.ID_Piege === selectedInsectocuteurId).ACTION?? ''}
                    onChange={handleInsectocuteurActionChange}
                  >
                    <MenuItem value="" disabled><em>Choisir une Action</em></MenuItem>
                    <MenuItem value="Changement de plaques">Changement de plaques</MenuItem>
                    <MenuItem value="Aucune Action">Aucune Action</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
        </Box>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2 }}
        >
          Etats des Equipements:
        </Typography> 
        <Box sx={{ flex: 2, p: 2, fontFamily:'roboto',
                borderRadius: '5px',
                boxShadow: 'none',
                border: '0.5px solid #C0C0C0',
                '& > :not(style)': { mb: 2 },}}>
          {selectedEquipements.map((equipement) => (
            <Box
              key={equipement.id}
              sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                p: 1, 
                borderRadius: '5px', 
                mb: 2 
              }}
            >
              <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ flex: 1 }}>{equipement.name}:</Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={equipement.ouiChecked}
                      onChange={() => handleEquipementOuiChange(equipement.id)}
                      sx={{ color: '#930e0e',
                        '&.Mui-checked': {
                          color: '#930e0e',
                        },
                    }}
                    />
                  }
                  label="Oui"
                  sx={{ m: 0, mr: 1 }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={equipement.nonChecked}
                      onChange={() => handleEquipementNonChange(equipement.id)}
                      sx={{ color: '#930e0e',
                        '&.Mui-checked': {
                          color: '#930e0e',
                        },
                    }}
                    />
                  }
                  label="Non"
                  sx={{ m: 0, mr:7 }}
                />
              </Box>
              <TextField
                label="Action à Prévoir"
                value={equipement.action}
                onChange={(event) => handleActionChange(event, equipement.id)}
                sx={{ ...inputStyles, width: '60%' }}
                variant="outlined"
                size="small"
              />
            </Box>
          ))}
        </Box>
        <Typography
          variant="h6"
          gutterBottom
          component="div"
          sx={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', width: '100%', mb: 2, mt: 2 }}
        >
         Recommandations:
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 2,
            fontFamily: 'roboto',
            borderRadius: '5px',
            boxShadow: 'none',
            border: '0.5px solid #C0C0C0',
            gap: 2,
            '& > :not(style)': { mb: 2 },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography>Observation:</Typography>
            <TextField
              multiline
              rows={4}
              value={observation}
              onChange={handleObservationChange}
              sx={{
                ...inputStyles,
                '& label.Mui-focused': {
                  color: '#930E0E',
                  fontWeight: 'bold',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              variant="outlined"
              fullWidth
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography>Conseils:</Typography>
            <TextField
              multiline
              rows={4}
              value={conseils}
              onChange={handleConseilsChange}
              sx={{
                ...inputStyles,
                '& label.Mui-focused': {
                  color: '#930E0E',
                  fontWeight: 'bold',
                },
                '& .MuiOutlinedInput-root': {
                  '&:hover fieldset': {
                    borderColor: '#930E0E',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#930E0E',
                  },
                },
              }}
              variant="outlined"
              fullWidth
            />
          </Box>
        </Box>
      </Box>
      :null}
      <Box sx={{display:"flex", justifyContent:"center" , columnGap:"50px"}}>
        {!isclient ? 
      <Button id="addRectButton" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"180px"
              }} onClick={SendToDB}>
            Enregistrer
          </Button>
          :null}
          <Button id="pdfDownload" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"180px"
              }} onClick={downloadPdf}>
            Telecharger Fichier
          </Button>
          </Box>



    </Box>
  );
}

export default TableFct;