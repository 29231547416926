import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, Select, MenuItem, FormControl, Alert, Snackbar } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

function UpdateMatiereActive() {
    const [nom, setNom] = useState('');
    const [open ,setOpen] = useState(false)
    const [opener ,setOpener] = useState(false)
    const [message , setmessage ] = useState("")
    const {id} = useParams()
    const navigate = useNavigate()
    const fontFamily = 'Roboto, sans-serif';
    useEffect(()=>{
        axios.get('http://pestima.test/api/matieresactives/'+id)
        .then((res)=>{
            setNom(res.data.Matieres_actives)

        })
    },[])
    const handlsubmit=()=>{
        const data = {
            Matieres_actives:nom,
        }
        axios.put("http://pestima.test/api/matieresactives/"+id,data)
        .then((res)=>{
            if(res.data.error){
                setmessage(res.data.message)
                setOpener(true)
            }else{
                setmessage(res.data.message)
                setOpen(true)
                setTimeout(() => {
                    navigate("/MatiereActiveTable")
                }, 3000);
            }
        })
    }

    const handleCloseer = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpener(false);
      };

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Ajouter matière active</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'space-between' }}>
                            <Box sx={{ minWidth: 'calc(50% - 16px)' }}>
                                <Typography sx={{ fontWeight: 'bold' }}>Nom de matière</Typography>
                                <TextField
                                    required
                                    fullWidth
                                    value={nom}
                                    onChange={(e) => { setNom(e.target.value) }}
                                    id="stockName"
                                    name="stockName"
                                    variant="outlined"
                                    sx={inputStyles}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }}
                            onClick={handlsubmit}
                            >
                                Ajouter
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Snackbar open={opener} autoHideDuration={6000} onClose={handleCloseer}>
                <Alert
                onClose={handleCloseer}
                severity="error"
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >
                {message}
                </Alert>
            </Snackbar>

        </Box>
    );
}

export default UpdateMatiereActive;
