import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { Box, Button, Modal, Typography } from '@mui/material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import fulllogo from './img/full_logo_png.png'


const piegesData = [
  { id: 1, type: "piege", etat: "Bien", codeRef: "T001" },
  { id: 2, type: "piege", etat: "Detruire", codeRef: "T002" },
  { id: 3, type: "piege", etat: "Bien", codeRef: "T003" },
];

const Plan = () => {
  const {idPlan} = useParams()
  const [postsData , setpostsData] = useState([])
  const [piegesData , setpiegesData] = useState([])
  const containerRef = useRef(null);
  const [rectCount, setRectCount] = useState(4);
  const layerRef = useRef(null);
  const trRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [initialImageState, setInitialImageState] = useState({
    src: null,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    scaleX: 1,
    scaleY: 1,
    rotation: 0
  });
  const [CodeRef, setCodeRef] = useState("");
  const [Type, setType] = useState("");
  const [Etat, setEtat] = useState("");
  const [selectedShape, setselectedShape] = useState(false);


  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openP, setOpenP] = useState(false);
  const handleOpenP = () => setOpenP(true);
  const handleCloseP = () => setOpenP(false);

  const [planData , setplanData] = useState()

  const formatDate = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {



    const containerWidth = containerRef.current.offsetWidth;
    const containerHeight = containerRef.current.offsetHeight;
    
    const stage = new Konva.Stage({
      container: containerRef.current,
      width: containerWidth,
      height: containerHeight,
      draggable: true,
    });

    const layer = new Konva.Layer();
    stage.add(layer);

    const initialRects = [];

    let selectedShape = null;

    // beg



    function createTitleBlock(stage, layer,convention) {

      const titleGroup = new Konva.Group({
        x: 10,
        y: 10,
        draggable: true
      });
    
      const outerRect = new Konva.Rect({
        width: 300,
        height: 170,
        stroke: 'black',
        strokeWidth: 2
      });
    
      const innerTopRect = new Konva.Rect({
        y: 55,
        width: 300,
        height: 5,
        stroke: 'black',
        strokeWidth: 1
      });
    
      const innerBottomRect = new Konva.Rect({
        y: 60,
        width: 300,
        height: 30,
        stroke: 'black',
        strokeWidth: 1
      });
      const innerBottomRect2 = new Konva.Rect({
        y: 95,
        width: 300,
        height: 35,
        stroke: 'black',
        strokeWidth: 1
      });
      const daterect = new Konva.Rect({
        y: 135,
        width: 100,
        height: 35,
        stroke: 'black',
        strokeWidth: 1
      });
      const datetext = new Konva.Text({
        x: 10,
        y: 150,
        text: "Date: "+formatDate(),
        fontSize: 8,
        fontStyle: 'bold'
      });
      const echrect = new Konva.Rect({
        x:100,
        y: 135,
        width: 100,
        height: 35,
        stroke: 'black',
        strokeWidth: 1
      });
      const echelletext = new Konva.Text({
        x: 110,
        y: 150,
        text: "Echelle: 1/200 ",
        fontSize: 8,
        fontStyle: 'bold'
      });
      const realiserrect = new Konva.Rect({
        x:200,
        y: 135,
        width: 100,
        height: 35,
        stroke: 'black',
        strokeWidth: 1
      });
      const realisertext = new Konva.Text({
        x: 210,
        y: 150,
        text: "Etabli par: Med",
        fontSize: 8,
        fontStyle: 'bold'
      });
      const logo = new Image();
      logo.src = fulllogo;
      const pestima = new Konva.Image({
        x: 100,
        y: 5,
        image:logo,
        width:90,
        height:30
      });
    
      const planText = new Konva.Text({
        x: 90,
        y: 35,
        text: "Plan d'appatage",
        fontSize: 15
      });
    
      const zalarText = new Konva.Text({
        x: 10,
        y: 68,
        text: convention ,
        fontSize: 14
      });
    
      const planMasseText = new Konva.Text({
        x: 10,
        y: 105,
        text: 'PLAN MASSE',
        fontSize: 14,
        fontStyle: 'bold'
      });
    
      titleGroup.add(outerRect, innerTopRect, daterect,datetext,echrect,echelletext,realiserrect,realisertext,innerBottomRect2,innerBottomRect,planText ,pestima,  zalarText, planMasseText);
      layer.add(titleGroup);
      layer.draw();
    }

    



    function createTrapezoid(width, height) {
      const topWidth = width * 0.8;
      const sideOffset = (width - topWidth) / 2; // Make the top 70% of the bottom width
      return new Konva.Shape({
        sceneFunc: function (context, shape) {
          context.beginPath();
          context.moveTo(sideOffset, 0);
          context.lineTo(width - sideOffset, 0);
          context.lineTo(width, height);
          context.lineTo(0, height);
          context.closePath();
          context.fillStrokeShape(shape);
        },
        width: width,
        height: height,
        fill: '#000000',
      });
    }

    function createRect(rectData) {
      const group = new Konva.Group({
        x: rectData.x,
        y: rectData.y,
        rotation: rectData.rotation,
        draggable: true,
      });
    
      let box;
      if (rectData.type === "piege") {
        box = createTrapezoid(rectData.width, rectData.height);
      } else {
        box = new Konva.Rect({
          width: rectData.width,
          height: rectData.height,
          fill: rectData.fill,
          cornerRadius: rectData.cornerRadius 
        });
      }
    
      const text = new Konva.Text({
        text: rectData.id.toString(),
        fontSize: (rectData.width + rectData.height) / 7.5,
        fontFamily: 'Arial',
        fill: rectData.fillText,
        width: rectData.width,
        height: rectData.height,
        align: 'center',
        verticalAlign: 'middle',
      });

      const deleteButton = new Konva.Group({
        x: rectData.width - 20,
        y: -10,
        visible: false,
      });

      const deleteCircle = new Konva.Circle({
        radius: 10,
        fill: 'red',
      });

      const deleteX = new Konva.Text({
        text: 'X',
        fontSize: 15,
        fontFamily: 'Arial',
        fill: 'white',
        align: 'center',
        verticalAlign: 'middle',
        x: -5,
        y: -7,
      });

      deleteButton.add(deleteCircle);
      deleteButton.add(deleteX);

      group.add(box);
      group.add(text);
      group.add(deleteButton);

      group.on('mouseover', function () {
        document.body.style.cursor = 'pointer';
      });

      group.on('mouseout', function () {
        document.body.style.cursor = 'default';
      });

      group.on('dragend', function (event) {
        UpdateInDataBase(event.target);
    });
  //   group.on('transform', function (event) {
      
  // });
      group.on('click tap', function (e) {
        e.cancelBubble = true;
        if (selectedShape) {
          removeTransformer(selectedShape);
          selectedShape = null;
        }
        deleteButton.visible(!deleteButton.visible());
        setselectedShape(!selectedShape);
        setCodeRef(rectData.codeRef);
        setType(rectData.type);
        setEtat(rectData.etat);
        if (!deleteButton.visible()) {
          addTransformer(group);
          selectedShape = group;
          setselectedShape(true);
        }
        layer.draw();
        logRectPositions();
      });

      stage.on('click tap', function () {
        deleteButton.visible(false);
        setselectedShape(false);
        if (selectedShape) {
          removeTransformer(selectedShape);
          selectedShape = null;
          layer.draw();
        }
        logRectPositions();
      });

      deleteButton.on('click tap', function (e) {
        e.cancelBubble = true;
        group.destroy();
        DeleteShape(group)
        layer.draw();
        logRectPositions();
      });

      group.on('transform', function () {
        const newWidth = box.width() * group.scaleX();
        const newHeight = box.height() * group.scaleY();
        
        if (rectData.type === "piege") {
          box.destroy();
          box = createTrapezoid(newWidth, newHeight);
          group.add(box);
          box.moveToBottom(); // Ensure the shape is behind the text
        } else {
          box.setAttrs({
            width: newWidth,
            height: newHeight,
          });
        }
        
        text.setAttrs({
          width: newWidth,
          height: newHeight,
          fontSize: (newWidth + newHeight) / 7.5
        });
        
        deleteButton.setAttrs({
          x: newWidth - 20,
        });
        
        group.setAttrs({
          scaleX: 1,
          scaleY: 1,
        });
      });

      group.id('rect-' + rectData.idShape);

      layer.add(group);
      
      return {etat : true , group : group} ;
    }
    

    
    function addTransformer(group) {
      const transformer = new Konva.Transformer({
        nodes: [group],
        keepRatio: false,
        boundBoxFunc: function (oldBox, newBox) {
          newBox.width = Math.max(30, newBox.width);
          newBox.height = Math.max(30, newBox.height);
          return newBox;
        },
      });
      layer.add(transformer);
    }

    function removeTransformer(group) {
      const transformer = group.getParent().find('Transformer')[0];
      if (transformer) {
        transformer.destroy();
        UpdateInDataBase(group);
        setselectedShape(false);
      }
    }

    initialRects.forEach(createRect);
    layer.draw();

    function addRectangle(data) {
      setRectCount((prevCount) => {
        let newRect
        if( data.fromDataBase){
          console.log("fromdatabase")
          newRect = {
            id: data.id,
            idShape:data.idShape,
            x: parseFloat(data.x),
            y:  parseFloat(data.y),
            width:  parseFloat(data.width), // Make piège slightly narrower
            height:  parseFloat(data.height), // Make piège slightly taller
            rotation: parseFloat(data.rotation),
            fill: data.type === "post" ? "#AFE1AF" : "#000000",
            type: data.type,
            etat: data.etat,
            codeRef: data.codeRef,
            fillText: data.type === "post" ? "#000000" : "#fff",
            cornerRadius: data.type === "post" ? 0 : 0, // Remove corner radius for piège
          };
        }else{
          console.log("not from database")
          newRect = {
            id: data.id,
            x:  Math.random() * (stage.width() - 100),
            y:  Math.random() * (stage.height() - 50),
            width:  data.type === "piege" ? 80 : 100, // Make piège slightly narrower
            height:  data.type === "piege" ? 60 : 50, // Make piège slightly taller
            rotation: 0,
            fill: data.type === "post" ? "#AFE1AF" : "#000000",
            type: data.type,
            etat: data.etat,
            codeRef: data.codeRef,
            fillText: data.type === "post" ? "#000000" : "#fff",
            cornerRadius: data.type === "post" ? 0 : 0, // Remove corner radius for piège
          };
        }

        const StateCreate = createRect(newRect);
        layer.draw();
        if(!data.fromDataBase && StateCreate.etat){
          SendShapeData(newRect , StateCreate.group );
        }
        
        return prevCount + 1;
      });
    }
    function SendShapeData(data , group){
            axios.post("http://pestima.test/api/shapes",
              {id_plan:idPlan,ID_Piege: data.type === 'piege' ? data.id : 0 , 
                ID_Post :data.type === 'post' ? data.id : 0  , x :data.x.toFixed(2) ,
                y:data.y.toFixed(2) , height:data.height , width:data.width , rotation:data.rotation.toFixed(2)
              })
            .then((res)=>{
              group.id('rect-' + res.data.id_shape)
              console.log("bien envoiyer")
            })
    }

    function UpdateInDataBase(group) {
      console.log('Current shape position:');
      const id = group.id().split('-')[1];
      const position = group.position();
      const rotation = group.rotation();
      
      // Find the shape (either Rect or Shape for trapezoid)
      const shape = group.findOne('Rect') || group.findOne('Shape');
      
      if (shape) {
          const width = shape.width();
          const height = shape.height();
          const type = shape instanceof Konva.Rect ? 'Post' : 'Piège';
          
          axios.put("http://pestima.test/api/shapes/"+id,
            {
              x :position.x.toFixed(2) ,
              y:position.y.toFixed(2) , height:height.toFixed(2) , width:width.toFixed(2) , rotation: rotation.toFixed(2)
            })
          .then((res)=>{
            console.log("bien changer")
            console.log(res.data)
          })
      }
  }

  function DeleteShape(group){
    const id = group.id().split('-')[1];
    axios.delete("http://pestima.test/api/shapes/"+id)
    .then((res)=>{
      console.log("bien supp")
    })
  }


    function logRectPositions() {
      console.log('Current shape positions:');
      stage.find('Group').forEach(function (group) {
        if (group.getParent() === layer) {
          const id = group.id().split('-')[1];
          const position = group.position();
          const rotation = group.rotation();
          
          // Find the shape (either Rect or Shape for trapezoid)
          const shape = group.findOne('Rect') || group.findOne('Shape');
          
          if (shape) {
            const width = shape.width();
            const height = shape.height();
            const type = shape instanceof Konva.Rect ? 'Post' : 'Piège';
            
            console.log(
              `${type} ${id}: x = ${position.x.toFixed(2)}, y = ${position.y.toFixed(2)}, ` +
              `width = ${width.toFixed(2)}, height = ${height.toFixed(2)}, rotation = ${rotation.toFixed(2)}`
            );
          }
        }
      });
    }
    window.addRectangle = addRectangle;

    logRectPositions();
    const imageObj = new Image();
    imageObj.src = 'http://pestima.test/api/plans/'+idPlan;

    const img = new Konva.Image({
      x: 0,
      y: 0,
      width: containerWidth,
      height: containerHeight,
      image:imageObj
    });
    layer.add(img);
    setBackgroundImage(img);

    axios.get("http://pestima.test/api/plan/"+idPlan)
    .then((res)=>{
      createTitleBlock(stage, layer,res.data.convention.client.entreprises[0] ? res.data.convention.client.entreprises[0].Nom :res.data.convention.client.personnes[0].Nom);
    })

    
    stage.on('wheel', (e) => {
      e.evt.preventDefault();
      const scaleBy = 1.1;
      const oldScale = stage.scaleX();
    
      const mousePointTo = {
        x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
        y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
      };
    
      let newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    
      if (newScale < 1) {
        newScale = 1;
      }
    
      const newPos = {
        x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
        y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
      };
    
      if (e.evt.deltaY < 0 && newScale === 1) {
        newPos.x = 0;
        newPos.y = 0;
      }
    
      stage.scale({ x: newScale, y: newScale });
      stage.position(newPos);
      stage.batchDraw();
    });

  axios.get("http://pestima.test/api/posts")
  .then((res)=>{
    setpostsData(res.data);
  })

  axios.get("http://pestima.test/api/pieges")
  .then((res)=>{
    setpiegesData(res.data);
  })

  axios.get("http://pestima.test/api/shapesbyplan/"+idPlan)
  .then((res) => {
    if (res.data.length > 0) {
      const postDataArray = res.data.filter(post => post.ID_Piege === 0);
      const piegeDataArray = res.data.filter(post => post.ID_Post === 0);
      postDataArray.forEach(postData => {
        const data = {
          id: postData.ID_Post,
          idShape : postData.id_shape,
          type: "post",
          etat: postData.post.etat.Etat,
          codeRef: postData.post.Code_Ref,
          x: postData.x,
          y: postData.y,
          height: postData.height,
          width: postData.width,
          rotation: postData.rotation,
          fromDataBase: true
        };
        console.log(postData);
        addRectangle(data);
      });

      piegeDataArray.forEach(postData => {
        const data = {
          id: postData.ID_Piege,
          idShape : postData.id_shape,
          type: "piege",
          etat: postData.post.etat.Etat,
          codeRef: postData.piege.Code_Ref,
          x: postData.x,
          y: postData.y,
          height: postData.height,
          width: postData.width,
          rotation: postData.rotation,
          fromDataBase: true
        };
        console.log(postData);
        addRectangle(data);
      });

    }
  });



  }, []);

  const handlePostSelection = (number) => {
    const postData = postsData.find(post => post.ID_Post === number);
    const data = {
      id: postData.ID_Post,
      type: "post",
      etat: postData.etat.Etat,
      codeRef: postData.Code_Ref,
      fromDataBase : false
    };
    window.addRectangle(data);
    handleClose();
  };

  const handlePiegeSelection = (number) => {
    const piegeData = piegesData.find(piege => piege.ID_Piege === number);
    const data = {
      id: piegeData.ID_Piege,
      type: 'piege',
      etat: piegeData.etat.Etat,
      codeRef: piegeData.Code_Ref
    };
    window.addRectangle(data);
    handleCloseP();
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    
    const formData = new FormData();
    formData.append('img', file);

    axios.post('http://pestima.test/api/plans/'+idPlan, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('Photo uploaded successfully:', response.data);
    })
    .catch(error => {
      console.error('Error uploading photo:', error);
    });

    reader.onload = (event) => {
      const img = new window.Image();
      
      img.onload = () => {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        
        setBackgroundImage((prevImg) => {
          if (prevImg) {
            prevImg.image(img);
            prevImg.width(containerWidth);
            prevImg.height(containerHeight);
          }
          return prevImg;
        });
  
        setInitialImageState({
          src: event.target.result,
          width: containerWidth,
          height: containerHeight,
          x: 0,
          y: 0,
          scaleX: 1,
          scaleY: 1,
          rotation: 0,
        });
  
        console.log('Image Size:', containerWidth, 'x', containerHeight);
        console.log('Image Position:', 'x:', 0, 'y:', 0);
      };
      
      img.src = event.target.result;
  
      // Adding Axios to upload the photo

    };
    
    reader.readAsDataURL(file);
  };
  
  return (
    <Box>
      <Navbar />
      <Box sx={{ display: 'flex', height: '500px'}}>
        <Box
          id="container"
          ref={containerRef}
          component="div"
          sx={{ 
            flexGrow: 1, 
            p: 0, 
            width: '80%', 
            height: '452px', 
            backgroundColor: '#8B4513', 
            overflowX: 'hidden',
            overflowY: 'hidden'
          }}
        >
        </Box>
        <Box
          sx={{
            width: "20%",
            height:"452px",
            paddingTop:"25px",
            backgroundColor: '#e8e8e8',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            rowGap:"80px"
          }}
        >
          <Box>
          <Button id="addRectButton" variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={handleOpen}>
            Ajouter Post
          </Button>
          <Button variant="contained" 
          sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }}
              onClick={handleOpenP}
              >
            Ajouter Piége
          </Button>
          <Button
        component="label"
        variant="contained"
        sx={{
          mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
          '&:hover': { bgcolor: '#a9281e' },
          color: 'white',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width:"130px"
        }}
        
      >
        Modifier Plan
        <input
          type="file"
          accept="image/*"
          onChange={handleFileInputChange}
          hidden
        />
      </Button>
      </Box>
      {selectedShape ? 
  <Box sx={{display:"flex", alignItems:"start", flexDirection:"column", width:"100%", paddingLeft:"15px"}}>
    <Typography>CodeRef : {CodeRef} </Typography>
    <Typography>Type : {Type} </Typography>
    <Typography>Etat : {Etat} </Typography>
  </Box>
  : null
}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Select a Post
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 , display:"flex" , alignItems:"center" , flexDirection:"column" }}>
            {postsData.map((post) => (
              <>
              {post.ID_Post !== 0 ? 
              <Button key={post.ID_Post} className='Posts' variant="contained"
              sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={() => handlePostSelection(post.ID_Post)}>
                Post {post.ID_Post}
              </Button>
              : null }
              </>
            ))}
          </Box>
        </Box>
      </Modal>
      <Modal
        open={openP}
        onClose={handleCloseP}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Select a Piége
          </Typography>
          <Box id="modal-description" sx={{ mt: 2 , display:"flex" , alignItems:"center" , flexDirection:"column" }}>
            {piegesData.map((piege) => (
              <>
              {piege.ID_Piege !== 0 ?
              <Button key={piege.ID_Piege} className='Posts' variant="contained"
              sx={{
                mb: 2, bgcolor: '#930E0E', borderRadius: '9px', fontFamily:'Roboto',
                '&:hover': { bgcolor: '#a9281e' },
                color: 'white',
                textTransform: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width:"130px"
              }} onClick={() => handlePiegeSelection(piege.ID_Piege)}>
                Piége {piege.ID_Piege}
              </Button>
              : null}
              </>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Plan;