import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
const passagesData = [
    { date: '19-03-2023', executedBy: 'John Doe' },
    { date: '22-04-2023', executedBy: 'Jane Smith' },
    { date: '15-05-2023', executedBy: 'Alex Brown' },
    { date: '10-06-2023', executedBy: 'Emily Davis' },
    { date: '12-07-2023', executedBy: 'Chris Wilson' }
];

function Passages() {
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 4; // Number of rows per page

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Calculate the data to show on the current page
    const paginatedData = passagesData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Passages"}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Box sx={{ mt: 2 }}>
                    <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Date du passage</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Executé par</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">FCP</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">FCT</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Certificate</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Rapport de passage</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedData.map((passage, index) => (
                                    <TableRow 
                                        key={index} 
                                        sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                    >
                                        <TableCell sx={{border:"0px"}}>{passage.date}</TableCell>
                                        <TableCell sx={{border:"0px"}}>{passage.executedBy}</TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination controls */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <IconButton 
                            onClick={handlePreviousPage} 
                            disabled={currentPage === 1} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        <Typography sx={{ mx: 2 }}>{currentPage}</Typography>
                        <IconButton 
                            onClick={handleNextPage} 
                            disabled={currentPage * rowsPerPage >= passagesData.length} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </Box>
    );
}

export default Passages;
