import React, { useState } from 'react';
import { Box ,  Card, CardContent, CardHeader, Typography  } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell, LineChart, Line, AreaChart, Area } from 'recharts'
import { UserCircle } from 'lucide-react'


const insectData = [
    { name: 'Rats', count: 20 },
    { name: 'Flies', count: 150 },
    { name: 'Mosquitoes', count: 300 },
    { name: 'Cockroaches', count: 80 },
    { name: 'Ants', count: 250 },
  ]
  
  const passageData = [
    { name: 'Completed', value: 75 },
    { name: 'Scheduled', value: 25 },
  ]
  
  const monthlyInfestationData = [
    { month: 'Jan', rats: 0.2, mosquitoes: 0.3 },
    { month: 'Feb', rats: 0.3, mosquitoes: 0.4 },
    { month: 'Mar', rats: 0.5, mosquitoes: 0.6 },
    { month: 'Apr', rats: 0.4, mosquitoes: 0.5 },
    { month: 'May', rats: 0.6, mosquitoes: 0.7 },
    { month: 'Jun', rats: 0.8, mosquitoes: 0.9 },
    { month: 'Jul', rats: 0.7, mosquitoes: 0.8 },
    { month: 'Aug', rats: 0.9, mosquitoes: 1.0 },
    { month: 'Sep', rats: 0.6, mosquitoes: 0.7 },
    { month: 'Oct', rats: 0.4, mosquitoes: 0.5 },
    { month: 'Nov', rats: 0.3, mosquitoes: 0.4 },
    { month: 'Dec', rats: 0.2, mosquitoes: 0.3 },
  ]
  
  const progressData = [
    { date: '2024-01-15', Measure1: 5, Measure2: 4 }, // Example passage with progress made on two measures
    { date: '2024-02-10', Measure1: 2, Measure2: 1 },
    { date: '2024-03-05', Measure1: 4, Measure2: 2 },
    { date: '2024-04-01', Measure1: 5, Measure2: 3 },
    { date: '2024-05-15', Measure1: 2, Measure2: 2 },
    { date: '2024-06-20', Measure1: 4, Measure2: 3 }, // Full progress for Measure1, close to full for Measure2
  ];
  
  
  const COLORS = ['#930E0E', '#000000']


function StatictiquesC() {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Statistiques"}/>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
  <Box
    display="grid"
    gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
    gap={4}
    mb={4} // Adds some margin to the bottom
  >
    {/* Card 1: Nombre d'insectes trouvés selon leur type */}
    <Card>
      <CardHeader title="Nombre d'insectes trouvés selon leur type" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={insectData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
            <XAxis dataKey="name" stroke="#000000" />
            <YAxis stroke="#000000" />
            <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
            <Legend wrapperStyle={{ color: '#000000' }} />
            <Bar dataKey="count" fill="#930E0E" />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>

    {/* Card 2: Pourcentage des passages passés par rapport aux passages programmés */}
    <Card>
      <CardHeader title="Passages passés par rapport aux passages programmés" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={passageData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {passageData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  </Box>

  <Box
    display="grid"
    gridTemplateColumns={{ xs: "1fr", md: "1fr 1fr" }}
    gap={4}
  >
    {/* Card 3: Taux d'infestation selon chaque mois */}
    <Card>
      <CardHeader title="Taux d'infestation selon chaque mois" />
      <CardContent>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={monthlyInfestationData}>
            <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
            <XAxis dataKey="month" stroke="#000000" />
            <YAxis stroke="#000000" />
            <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
            <Legend wrapperStyle={{ color: '#000000' }} />
            <Line type="monotone" dataKey="rats" stroke="#930E0E" activeDot={{ r: 8, fill: '#930E0E' }} />
            <Line type="monotone" dataKey="mosquitoes" stroke="#000000" activeDot={{ r: 8, fill: '#000000' }} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>

    {/* Card 4: Taux de consommation des produits */}
    <Card>
  <CardHeader title="Progrès des Mesures Préventives" />
  <CardContent>
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={progressData}>
        <CartesianGrid strokeDasharray="3 3" stroke="#000000" />
        <XAxis dataKey="date" stroke="#000000" />
        <YAxis  stroke="#000000" />
        <Tooltip contentStyle={{ backgroundColor: '#fff', border: '1px solid #000000' }} />
        <Legend wrapperStyle={{ color: '#000000' }} />
        
        {/* Measure1 with custom legend name */}
        <Area 
          type="monotone" 
          dataKey="Measure2" 
          name="Mesures Préventives soulevés"
          stackId="1" 
          stroke="#930E0E" 
          fill="#930E0E" 
          fillOpacity={0.5} 
        />
        
        {/* Measure2 with custom legend name */}
        <Area 
          type="monotone" 

          dataKey="Measure1" 
          name="Mesures Préventives"  
          stackId="1" 
          stroke="#000000" 
          fill="#000000" 
          fillOpacity={0.5} 
        />
      </AreaChart>
    </ResponsiveContainer>
  </CardContent>
</Card>


  </Box>
</Box>

            <Footer />
        </Box>
    );
}

export default StatictiquesC;
