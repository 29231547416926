import React, { useState } from 'react';
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, IconButton } from '@mui/material';
import Navbar from './NavbarC.js';
import Footer from './FooterC.js';
import DefaultPlan from './img/DefaultPlan.jpeg';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const produitsData = [
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023',organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' },
    { name: 'Product X', matiere: 'Mat A', dateDePermption: '19-03-2023', organisme: 'Rats', authorization: '001' }
];

const DocumentData = [
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },
    { name: 'Product X' },

];

function Accueil() {
    const [activeTab, setActiveTab] = useState('Plan');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 4; // Number of rows per page

    const [currentPageD, setCurrentPageD] = useState(1);
    const rowsPerPageD = 4; // Number of rows per page

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handleNextPageD = () => {
        setCurrentPageD((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    const handlePreviousPageD = () => {
        setCurrentPageD((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    };

    // Calculate the data to show on the current page
    const paginatedData = produitsData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const paginatedDataDocument = DocumentData.slice(
        (currentPageD - 1) * rowsPerPageD,
        currentPageD * rowsPerPageD
    );

    const renderContent = () => {
        switch (activeTab) {
            case 'Plan':
                return (
                    <Box sx={{ mt: 2 }}>
                        <Box sx={{width:"100%" , height:"100%" , display:"flex" ,justifyContent:"center"}}>
                        <img
                            src={DefaultPlan}
                            alt="Plan"
                            style={{ maxWidth: '100%', height: 'auto', marginBottom: '20px' }}
                        />
                        </Box>

                       <Box sx={{ display: 'flex', justifyContent: 'space-around', gap: 3 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'green', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Post Bon Etat</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'yellow', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Post inaccessible</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ bgcolor: 'black', width: '20px', height: '20px', mr: 1 }} />
                                <Typography sx={{ color: 'black', fontWeight: 'bold' }}>Piège</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Button
                        variant="contained"
                        sx={{
                            bgcolor: '#930e0e',
                            borderRadius: '9px',
                            textTransform: 'none',
                            width: '200px',
                            color: 'white',
                            '&:hover': { bgcolor: '#a9281e' },
                        }}
                    >
                        Telecharger
                    </Button>
                </Box>
                    </Box>
                );
            case 'Produits':
                return (
                    <Box sx={{ mt: 2 }}>
                        <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Nom</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Matières Actives</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Date de péremption</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Organisme Cible</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">N D’autorisation</Typography></TableCell>
                                        <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Certificate</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paginatedData.map((produit, index) => (
                                        <TableRow 
                                            key={index} 
                                            sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                        >
                                            <TableCell sx={{border:"0px"}}>{produit.name}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.matiere}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.dateDePermption}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.organisme}</TableCell>
                                            <TableCell sx={{border:"0px"}}>{produit.authorization}</TableCell>
                                            <TableCell sx={{border:"0px"}}>
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        bgcolor: '#930e0e',
                                                        borderRadius: '9px',
                                                        textTransform: 'none',
                                                        color: 'white',
                                                        '&:hover': { bgcolor: '#a9281e' },
                                                    }}
                                                >
                                                    Telecharger
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* Pagination controls */}
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                            <IconButton 
                                onClick={handlePreviousPage} 
                                disabled={currentPage === 1} 
                                sx={{ color: '#930e0e' }} // Red color for arrow
                            >
                                <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                            </IconButton>
                            <Typography sx={{ mx: 2 }}>{currentPage}</Typography>
                            <IconButton 
                                onClick={handleNextPage} 
                                disabled={currentPage * rowsPerPage >= produitsData.length} 
                                sx={{ color: '#930e0e' }} // Red color for arrow
                            >
                                <PlayArrowIcon />
                            </IconButton>
                        </Box>
                    </Box>
                );
            case 'Documents':
                return (
                    <Box sx={{ mt: 2 }}>
                    <TableContainer style={{ borderRadius: '9px', border: '1px solid #C0C0C0', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Nom de Documents</Typography></TableCell>
                                    <TableCell sx={{border:"0px"}}><Typography fontWeight="bold">Telechargement</Typography></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paginatedDataDocument.map((produit, index) => (
                                    <TableRow 
                                        key={index} 
                                        sx={{ borderBottom: '1px solid #C0C0C0' }} // Add border to each row
                                    >
                                        <TableCell sx={{border:"0px"}}>{produit.name}</TableCell>
                                        <TableCell sx={{border:"0px"}}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#930e0e',
                                                    borderRadius: '9px',
                                                    textTransform: 'none',
                                                    color: 'white',
                                                    '&:hover': { bgcolor: '#a9281e' },
                                                }}
                                            >
                                                Telecharger
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination controls */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 3 }}>
                        <IconButton 
                            onClick={handlePreviousPageD} 
                            disabled={currentPageD === 1} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon sx={{ transform: 'rotate(180deg)' }} />
                        </IconButton>
                        <Typography sx={{ mx: 2 }}>{currentPageD}</Typography>
                        <IconButton 
                            onClick={handleNextPageD} 
                            disabled={currentPageD * rowsPerPageD >= DocumentData.length} 
                            sx={{ color: '#930e0e' }} // Red color for arrow
                        >
                            <PlayArrowIcon />
                        </IconButton>
                    </Box>
                </Box>
                )
            default:
                return null;
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Navbar activeButton={"Accueil"}/>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                <Button
                    onClick={() => handleTabChange('Plan')}
                    sx={{
                        bgcolor: activeTab === 'Plan' ? '#930e0e' : 'white',
                        color: activeTab === 'Plan' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Plan' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Plan
                </Button>
                <Button
                    onClick={() => handleTabChange('Produits')}
                    sx={{
                        bgcolor: activeTab === 'Produits' ? '#930e0e' : 'white',
                        color: activeTab === 'Produits' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Produits' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Produits
                </Button>
                <Button
                    onClick={() => handleTabChange('Documents')}
                    sx={{
                        bgcolor: activeTab === 'Documents' ? '#930e0e' : 'white',
                        color: activeTab === 'Documents' ? 'white' : 'black',
                        borderRadius: '9px',
                        textTransform: 'none',
                        width: '150px',
                        mx: 1,
                        '&:hover': { bgcolor: activeTab === 'Documents' ? '#a9281e' : '#f5f5f5' },
                    }}
                >
                    Documents
                </Button>
            </Box>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                {renderContent()}
                
            </Box>
            <Footer />
        </Box>
    );
}

export default Accueil;
