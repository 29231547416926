import React from 'react';
import { Box, Typography } from '@mui/material';
import PestimaLogo from './img/Pestima.png'; // Path to your logo

function Footer() {
  return (
    <Box sx={{
      width: '100%',
      textAlign: 'center',
      position: 'relative',
      mt: 'auto',
      overflow: 'hidden',
    }}>
      {/* Logo */}
      <Box sx={{ padding: '10px 0' }}>
        <img src={PestimaLogo} alt="Pestima Logo" style={{ height: '40px' }} />
      </Box>

      <Box sx={{
        position: 'relative',
        width: '100%',
        height: '150px', // Larger height to fit both red and black parts
        backgroundColor: '#930e0e', // Red background for the curve
        borderTopLeftRadius: '50% 40%',
        borderTopRightRadius: '50% 40%',
        overflow: 'hidden',
      }}>
        {/* Black curve inside the red box */}
        <Box sx={{
          position: 'absolute',
          width: '100%',
          height: '80px',
          bottom: 0,
          backgroundColor: 'black',
          borderTopLeftRadius: '50% 40%',
          borderTopRightRadius: '50% 40%',
        }}>
          {/* Copyright text */}
          <Typography variant="body2" sx={{
            color: 'white',
            fontSize: '14px',
            fontFamily: 'Roboto, sans-serif',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}>
            &copy; 2024
          </Typography>
        </Box>

        {/* "Powered By Rakops" text */}
        <Typography variant="body1" sx={{
          color: 'white',
          fontSize: '16px',
          fontFamily: 'Roboto, sans-serif',
          position: 'absolute',
          top: '25%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}>
          Powered By Rakops
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
