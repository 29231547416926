import React, { useState } from 'react';
import { Box, TextField, Typography, Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const formatDate = (newdate = new Date()) => {
    const date = new Date(newdate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  

function AuditForm() {
    const [questions, setQuestions] = useState([{ major: '', minors: [''] }]);
    const navigate = useNavigate()
    const handlesubmit=()=>{
        axios.post('http://pestima.test/api/audits',{Date:formatDate()})
        .then((res)=>{
            questions.map((q)=>{
                axios.post('http://pestima.test/api/categories',{ID_Audit:res.data.ID_Audit ,Category:q.major})
                .then((res)=>{
                    q.minors.map((qm)=>{
                        axios.post('http://pestima.test/api/questions',{Question:qm,ID_Category:res.data.ID_Category})
                        .then((res)=>{
                            console.log(res.data)
                            setTimeout(() => {
                                navigate('/AuditTable')
                            }, 3000);
                        })
                    })
                })
            })
            
        })
    }

    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    const handleAddQuestionSet = () => {
        setQuestions([...questions, { major: '', minors: [''] }]);
    };

    const handleAddMinorQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].minors.push('');
        setQuestions(newQuestions);
    };

    const handleQuestionChange = (index, value, type, minorIndex = null) => {
        const newQuestions = [...questions];
        if (type === 'major') {
            newQuestions[index].major = value;
        } else if (type === 'minor') {
            newQuestions[index].minors[minorIndex] = value;
        }
        setQuestions(newQuestions);
    };

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Audit Form</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
                        {questions.map((questionSet, index) => (
                            <Box key={index} sx={{ width: '100%', mt: index > 0 ? 4 : 0 }}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Question Major {index + 1}</Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        value={questionSet.major}
                                        onChange={(e) => handleQuestionChange(index, e.target.value, 'major')}
                                        id={`questionMajor-${index}`}
                                        name={`questionMajor-${index}`}
                                        variant="outlined"
                                        sx={inputStyles}
                                        InputProps={{
                                            endAdornment: (
                                                index === questions.length - 1 && (
                                                    <IconButton onClick={handleAddQuestionSet}>
                                                        <Add />
                                                    </IconButton>
                                                )
                                            ),
                                        }}
                                    />
                                </Box>
                                {questionSet.minors.map((minorQuestion, minorIndex) => (
                                    <Box key={minorIndex} sx={{ width: '100%', mt: 2 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Question Minor {minorIndex + 1}</Typography>
                                        <TextField
                                            required
                                            fullWidth
                                            value={minorQuestion}
                                            onChange={(e) => handleQuestionChange(index, e.target.value, 'minor', minorIndex)}
                                            id={`questionMinor-${index}-${minorIndex}`}
                                            name={`questionMinor-${index}-${minorIndex}`}
                                            variant="outlined"
                                            sx={inputStyles}
                                            InputProps={{
                                                endAdornment: (
                                                    minorIndex === questionSet.minors.length - 1 && (
                                                        <IconButton onClick={() => handleAddMinorQuestion(index)}>
                                                            <Add />
                                                        </IconButton>
                                                    )
                                                ),
                                            }}
                                        />
                                    </Box>
                                ))}
                            </Box>
                        ))}
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }} 
                            onClick={handlesubmit}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AuditForm;
